<template>
    <front-layout>
        <v-container fluid>
            <v-container>
                <v-row>
                    <v-col md="9">
                        <v-row>
                            <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12"
                                class="bank-first-row d-flex align-center flex-wrap">
                                <div class="bg_image">
                                    <div class="d-flex justify-start align-center">
                                        <v-img :src="bank.logo_url" class="bank-first-row__logo"></v-img>
                                        <h1 class="white--text">{{ bank.name }}</h1>
                                    </div>
                                    <p class="text--white fz-18 mt-6 mb-8 min-width100">{{ bank.description_hy }}</p>
                                </div>
                            </v-col>
                        </v-row>
                        <!-- Form select apartment START -->
                        <v-row class="sizing">
                            <v-col>
                                <div class="wrap_violet_form">
                                    <v-form class="d-flex flex-wrap">
                                        <div
                                            class="wrap_first_line_form d-flex flex-wrap flex-lg-nowrap justify-space-between justify-lg-start">
                                            <div class="wrap_inp max-width50-beforeLG mr-3 mb-3 mb-lg-0 mt-3 mt-lg-2">
                                                <span class="subtitle_input white--text mt-3 mt-lg-3">Стоимость
                                                    жилья</span>
                                                <v-text-field @keydown="validateNumberKeys" v-model="displayCost"
                                                    class="main_color_text input_text_project rounded-0"
                                                    label="3500000 Р" solo>
                                                    <template v-slot:append>
                                                        <v-slider style="" :color="white"
                                                            class="slider_term main_color_text main_color w-310"
                                                            v-model="housingCost" :min="0" :max="100000000"
                                                            hide-details>
                                                        </v-slider>
                                                    </template>
                                                </v-text-field>
                                            </div>
                                            <div
                                                class="wrap_inp max-width50-beforeLG mr-0 mr-lg-3 mt-3 mt-lg-2 mb-10 mb-lg-0">
                                                <span class="subtitle_input white--text">Первоначальный взнос</span>
                                                <v-text-field @keydown="validateNumberKeys" v-model="displayFee"
                                                    class="main_color_text align-center slider_term_text_field rounded-0"
                                                    hide-details label="Срок" solo>
                                                    <template v-slot:append>
                                                        <v-slider style="" :color="white"
                                                            class="slider_term main_color_text main_color w-310"
                                                            v-model="initialFee" :min="0" :max="100000000" hide-details>
                                                        </v-slider>
                                                    </template>
                                                </v-text-field>
                                            </div>
                                            <div class="wrap_inp w-310 mt-9 mt-lg-2">
                                                <span class="subtitle_input white--text mt-3 mt-lg-3">Срок</span>
                                                <v-select :label="periods[0]"
                                                    class="color-icon-main input_text_project rounded-0"
                                                    id="color-icon-main" append-icon="mdi-chevron-down" solo depressed
                                                    :items="periods"></v-select>
                                            </div>
                                        </div>
                                        <div class="wrap_form_btn mr-3">
                                            <v-btn
                                                class="text--white transparent text-capitalize subtitle-1 mr-3 btn__clear"
                                                depressed>{{ $t('headers.form_filter_btn_clear') }}
                                            </v-btn>
                                            <v-btn
                                                class="main_color_text filter_submit_btn text-capitalize subtitle-1 font-weight-bold"
                                                depressed>{{ $t('headers.form_filter_btn_apply') }}
                                            </v-btn>
                                        </div>
                                    </v-form>
                                </div>
                            </v-col>
                        </v-row>
                        <!-- Form select apartment FINISH -->
                        <v-row>
                            <v-col class="d-flex justify-space-between flex-wrap flex-lg-nowrap"
                                v-for="item in bank.mortgages" :key="item.id">
                                <div class="d-flex justify-space-between align-center circle-data">
                                    <div class="circle_statistic">
                                        <v-progress-circular class="progress_circle" :rotate="0" :size="93" :width="18"
                                            :value="50" :color="indigo">
                                        </v-progress-circular>
                                        <v-progress-circular class="progress_circle" :rotate="-180" :size="93"
                                            :width="18" :value="25" :color="green">
                                        </v-progress-circular>
                                        <v-progress-circular class="progress_circle" :rotate="-90" :size="93"
                                            :width="18" :value="25" :color="yellow">
                                        </v-progress-circular>
                                    </div>

                                    <v-list class="ml-auto bank_offer_list">
                                        <v-list-item
                                            class="mb-n3 d-flex justify-space-between align-baseline bank_offer_list__item">
                                            <v-list-item-title class="bank_offer_list__head">Начальный взнос
                                            </v-list-item-title>
                                            <v-list-item-title class="font-weight-bold bank_offer_list__text-green">
                                                1,500,000 ₽
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item class="mb-n3 bank_offer_list__item">
                                            <v-list-item-title class="bank_offer_list__head">Сумма кредита
                                            </v-list-item-title>
                                            <v-list-item-title class="font-weight-bold bank_offer_list__text-indigo">
                                                7,071,125 ₽
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item class="mb-n3 bank_offer_list__item">
                                            <v-list-item-title class="bank_offer_list__head">Переплата
                                            </v-list-item-title>
                                            <v-list-item-title class="font-weight-bold bank_offer_list__text-yellow">
                                                1,876,754 ₽
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </div>
                                <div class="mortgage-data">
                                    <ul class="mortgage-data__list flex-nowrap flex-lg-wrap max-width100">
                                        <li class="mortgage-data__item">
                                            <div class="mortgage-data__head font-weight-light">{{ $t('headers.program')
                                            }}</div>
                                            <div class="mortgage-data__value font-weight-bold">{{ item.name }}</div>
                                        </li>
                                        <li class="mortgage-data__item">
                                            <div class="mortgage-data__head font-weight-light">{{ $t('headers.bid') }}
                                            </div>
                                            <div class="mortgage-data__value font-weight-bold">{{ item.min_rate }}</div>
                                        </li>
                                        <li class="mortgage-data__item">
                                            <div class="mortgage-data__head font-weight-light">{{
                                            $t('headers.beginning_contribution') }}</div>
                                            <div class="mortgage-data__value font-weight-bold">-ից {{
                                            item.percentage_initial_payment }}%</div>
                                        </li>
                                        <li class="mortgage-data__item">
                                            <div class="mortgage-data__head font-weight-light">{{
                                            $t('headers.credit_term') }}</div>
                                            <div class="mortgage-data__value font-weight-bold">до 30 лет</div>
                                        </li>
                                        <li class="mortgage-data__item" v-if="$vuetify.breakpoint.width > 900">
                                            <div class="mortgage-data__head font-weight-light">{{
                                            $t('headers.payment_per_month') }}</div>
                                            <div class="mortgage-data__value font-weight-bold">56 743 Р</div>
                                        </li>
                                    </ul>
                                </div>
                            </v-col>
                        </v-row>

                        <!-- Reviews -->
                        <v-row>
                            <v-col xs="12" cols="12">
                                <v-row>
                                    <v-col>
                                        <h3 class="main_color_text font-weight-bold mb-4 text-h5 text-left">
                                            {{ $t('headers.reviews') }}</h3>
                                        <div v-for="review in bank.reviews" :key="'review' + review.id">
                                            <comments-card :comment_data="review" />
                                        </div>
                                    </v-col>
                                </v-row>
                                <!-- Review -->
                                <v-row>
                                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12" class="px-0 px-md-2 mb-6">
                                        <h3 class="main_color_text font-weight-bold mb-4 text-h6">
                                            {{ $t('headers.leave_feedback') }}</h3>
                                        <comment-form @update="updateBank" :reviewable_type="'App\\Models\\Bank'"
                                            :reviewable_id="bank.id" />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col md="3" class="pt-0 pr-0 pl-0 pl-md-4">
                        <div v-if="$vuetify.breakpoint.width > 900" class="mt-6 mt-md-0">
                            <aside-link-variable-card />
                        </div>

                        <advertising :limit="2" />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </front-layout>
</template>

<script>
import FrontLayout from "@/layouts/FrontLayout";
import AsideLinkVariableCard from "@/components/cards/AsideLinkVariableCard";
import Bank from "@/models/Bank";
import CommentsCard from "../../../components/cards/CommentsCard";
import CommentForm from "../../../components/forms/CommentForm";
import { computedNumberFormatter } from "@/utils/number.formatter";

export default {
    name: "bank.show",
    components: {
        FrontLayout,
        AsideLinkVariableCard,
        CommentsCard,
        CommentForm,
        Advertising: () => import('@/components/ads/Advertising'),
    },
    computed: {
        displayCost: computedNumberFormatter('housingCost'),
        displayFee: computedNumberFormatter('initialFee')
    },
    data: function () {
        return {
            isLoading: false,
            first_map_height: 500,
            periods: ['Любой', '1 год', '2 года', '4 года', '5 лет', '10 лет', '15 лет', '20 лет', '25 лет', '30 лет'],
            bank: {},
            bank_qty: 3,
            initialFee: 0,
            housingCost: 0,
            indigo: '#3F44C3',
            green: '#42B261',
            yellow: '#D6A700',
            white: 'white',
            bank_name:''
        }
    },
    metaInfo() {
        return {
            title: this.$t('meta.bank_with_name_title', {bank_name: this.bank_name}),
            meta: [
                { name: 'description', content: this.$t('meta.bank_with_name_description', {bank_name: this.bank_name}) }
            ]
        }
    },
    methods: {
        async init() {
            this.isLoading = true
            let slug = this.$route.params.bank
            if (slug) {
                this.bank = await Bank.custom('/bank/slug/' + slug).first()

                this.setBankName()
            }

            this.isLoading = false
        },
        setBankName(){
            if(this.$i18n.locale === 'ru'){
                this.bank_name = this.bank.name_ru
            }else if(this.$i18n.locale === 'en'){
                this.bank_name = this.bank.name_en
            }else {
                this.bank_name = this.bank.name_hy
            }
        },
        chunkArray(arr, qty) {
            return arr.reduce((prev, cur, i, a) => !(i % qty) ? prev.concat([a.slice(i, i + qty)]) : prev, []);
        },
        first_map_height_set() {
            this.first_map_height = window.innerWidth > 1240 ? 500 : 200
        },
        updateBank(review) {
            this.bank.reviews.push(review)
        },
        /* Prevents default on keydown when any key is pressed except number keys
         and 'Tab', 'Del', 'Backspace', 'Left/Right arrow' */
        validateNumberKeys(e) {
            if (e.key.match(/\D/g) && ![8, 9, 37, 39, 46].includes(e.keyCode)) {
                e.preventDefault()
            }
        }
    },
    mounted() {
        this.init()
        window.addEventListener("resize", this.first_map_height_set)
        this.first_map_height_set()
    }
}
</script>

<style lang="scss" scoped>
.bank_offer_list__head {
    font-size: 14px;

    @media all and (max-width: 770px) {
        font-size: 12px;
    }
}

.bg_image {
    position: relative;
    z-index: 10;

    &::before {
        content: '';
        display: inline-block;
        background: url('../../../assets/img/project-back.jpg') center center/cover no-repeat no-repeat;
        position: absolute;
        top: -100px;
        right: -12px;
        bottom: -12px;
        width: 2000px;
        height: inherit;
        z-index: -1;
    }
}

.bank-first-row__logo {
    width: 133px;
    max-width: 133px;
    max-height: 133px;
    margin-right: 30px;
}

.max-width100 {
    max-width: 100%;
}

.min-width100 {
    min-width: 100%;
}

.max-width300 {
    max-width: 300px;
}

.fz-18 {
    font-size: 18px;
}

.fz-16 {
    font-size: 16px;
}

.fz-24 {
    font-size: 24px;
}

.text--white {
    color: white;
}

.bg_violet {
    background: #8e91d9 !important;
}

.choose-apartment-H2 {
    display: block;
    text-align: left;
    min-width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    padding: 20px 10px;
}

.filter_submit_btn {
    background: #ffffff !important;
    border: 2px solid #3F44C4;
    box-sizing: border-box;
    width: 190px !important;
    height: 50px !important;
    border-radius: 0;
}

.slider_term_text_field {
    position: relative;
    height: 48px;
    color: var(--main_color-base) !important;
}

.slider_term {
    color: var(--main_color-base) !important;
    position: absolute;
    bottom: -40px;
    right: 0;
    width: 350px;
}

.wrap_inp {
    position: relative;

    @media all and (max-width: 1240px) {
        max-height: 60px;
    }

    .subtitle_input {
        position: absolute;
        color: white;
        top: -45px;
        line-height: 1.2em;
        left: 0;
        font-size: 16px;
    }
}

.w-310 {
    width: 310px;

    @media all and (max-width: 1240px) {
        width: 100%;
        min-width: 100%;
    }
}

.max-width45 {
    @media all and (max-width: 1240px) {
        max-width: 45%;
    }
}

/* Form violet bg*/
.input_checkbox {
    overflow: hidden;
    background: white;
    padding: 0 12px;
    height: 48px;
    line-height: 20px;
    margin: 0;
    position: relative;

    @media all and (max-width: 770px) {
        margin-bottom: 12px;
    }

    .v-input--selection-controls__ripple,
    .mdi-checkbox-blank-outline,
    .mdi-checkbox-marked {
        width: 20px;
        height: 20px;
        max-width: 20px;
        max-height: 20px;
        font-size: 14px;
        transform: translateX(10px);
    }
}

.input_text_project {
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    font-size: 14px !important;

    label.v-label {
        font-size: 14px !important;
    }
}

.wrap_form_btn {
    display: flex;
    align-items: center;
    justify-content: right;
    min-width: 100%;
    margin-top: 30px;
    max-height: 48px;

    @media all and (max-width: 900px) {
        margin-bottom: 30px;
    }

    @media all and (max-width: 770px) {
        min-width: 100%;
        justify-content: space-between;
    }
}

.wrap_first_line_form {
    max-height: 48px;
    margin-bottom: 15px;

    @media all and (max-width: 1240px) {
        max-height: 100%;
        margin-bottom: 0;
    }
}

.wrap_violet_form {
    margin-top: 30px;
    padding-bottom: 20px;
    position: relative;
    z-index: 9;

    &::before {
        content: '';
        display: inline-block;
        background: #8e91d9 !important;
        position: absolute;
        top: -100px;
        right: -12px;
        bottom: 0;
        width: 3000px;
        height: inherit;
        z-index: -2;
    }
}

.btn__clear {
    min-height: 48px;
}

.bank_offer_list {
    width: 280px;

    @media all and (max-width: 770px) {
        width: 200px;
    }

    .bank_offer_list__text-indigo {
        color: var(--main_color-base);
        text-align: right;

        @media all and (max-width: 770px) {
            font-size: 12px;
        }
    }

    .bank_offer_list__text-green {
        color: var(--green_color-base);
        text-align: right;

        @media all and (max-width: 770px) {
            font-size: 12px;
        }
    }

    .bank_offer_list__text-yellow {
        color: var(--yellow_color-base);
        text-align: right;

        @media all and (max-width: 770px) {
            font-size: 12px;
        }
    }

    .bank_offer_list__item {
        padding: 0;
        font-size: 12px;

        @media all and (max-width: 900px) {
            margin-top: -10px;
            max-height: 25px;
            margin-bottom: -20px !important;
        }
    }
}

.circle_statistic {
    width: 95px;
    height: 95px;
    position: relative;
    margin-right: 10px;

    .progress_circle {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.bg_white {
    background: #fff;
}

.circle-data {
    background: #fff;
    width: 505px;
    height: 171px;
    padding: 30px;

    @media all and (max-width: 1240px) {
        min-width: 100%;
        margin-bottom: 20px;
    }
}

.mortgage-data {
    padding: 0 12px 12px 12px;

    @media all and (max-width: 1240px) {
        min-width: 100%;
    }
}

.mortgage-data__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;

    .mortgage-data__item {
        padding: 0 0 25px 0;
        list-style: none;
        min-width: 45%;

        @media all and (max-width: 1240px) {
            min-width: 0;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        .mortgage-data__head {
            color: #9a9a9a;
            font-size: 12px;

            @media all and (max-width: 770px) {
                font-size: 10px;
            }
        }

        .mortgage-data__value {
            color: var(--main_color-base);
            font-size: 14px;

            @media all and (max-width: 770px) {
                font-size: 12px;
            }
        }
    }
}

.min-beforeLG-width100 {
    @media all and (max-width: 1240px) {
        min-width: 100%;
    }
}

.max-width50-beforeLG {
    @media all and (max-width: 900px) {
        max-width: calc(50% - 12px);
    }
}
</style>
